<template>
  <Listbox
    v-slot="{ open }"
    as="div"
    :model-value="value"
    @update:model-value="val => emits('update:modelValue', val)"
    @click.stop="() => {}"
  >
    <ListboxLabel
      v-if="props.label"
      class="block text-sm font-medium leading-6"
    >
      {{ label }}
    </ListboxLabel>
    <div class="relative">
      <ListboxButton
        :class="getButtonClass"
        :aria-label="props.getLabel(props.modelValue)"
        @click.stop.prevent="() => {}"
      >
        <span :class="getButtonLabelClass">
          {{ props.getLabel(props.modelValue) }}
        </span>
        <span
          v-if="!props.getLabel(props.modelValue) && placeholder"
          class="block truncate text-black/80 leading-[11px] font-normal"
        >
          {{ placeholder }}
        </span>
        <span
          :class="[
            'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'
          ]"
        >
          <IconsDropdownChevron :active="open" :class="chevronClass" />
        </span>
      </ListboxButton>
      <app-transitions-dropdown>
        <ListboxOptions :class="getListboxOptionsClass">
          <div
            v-if="showBackdrop"
            class="backdrop-blur-sm bg-white/[0.8] absolute w-full h-full -z-[1]"
          ></div>
          <ListboxOption
            v-for="option in props.options"
            :key="props.getValue(option)"
            v-slot="{ active, selected }"
            :value="option"
            :class="optionContainerClass"
            @click.stop.prevent="() => {}"
          >
            <li :class="getOptionClass(active)">
              <IconsCheck
                v-if="selected && showCheckIcon"
                class="absolute left-2.5 flex top-[14px] w-2.5 h-2.5 text-green-swamp"
                :class="checkIconClass"
              />
              <span :class="getOptionLabelClass(selected)">
                {{ props.getLabel(option) }}
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </app-transitions-dropdown>
    </div>
    <p
      v-if="(error && typeof error === 'string') || errorMessage"
      class="text-xs font-light text-red-hot mt-2"
    >
      {{ error || errorMessage }}
    </p>
    <ErrorMessage
      v-else
      :name="name"
      class="text-xs text-red-hot mt-2"
      :as="'p'"
    />
  </Listbox>
</template>

<script setup>
import { computed, toRef } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { twMerge } from 'tailwind-merge'
import { useField } from 'vee-validate'
import uniqueId from 'lodash/uniqueId'

const props = defineProps({
  modelValue: { type: [String, Number, Object], default: '' },
  options: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: ''
  },
  getLabel: {
    type: Function,
    default: option => option.label
  },
  getValue: {
    type: Function,
    default: option => option.value
  },
  name: {
    type: String,
    default: uniqueId('app-select-name-')
  },
  buttonClass: {
    type: String,
    default: ''
  },
  buttonLabelClass: {
    type: String,
    default: ''
  },
  optionActiveClass: {
    type: String,
    default: ''
  },
  optionContainerClass: {
    type: String,
    default: ''
  },
  optionClass: {
    type: String,
    default: ''
  },
  optionLabelClass: {
    type: String,
    default: ''
  },
  listboxOptionsClass: {
    type: String,
    default: ''
  },
  chevronClass: {
    type: String,
    default: ''
  },
  showBackdrop: {
    type: Boolean,
    default: false
  },
  showCheckIcon: {
    type: Boolean,
    default: false
  },
  checkIconClass: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  rules: {
    type: String,
    default: undefined
  },
  error: {
    type: [String, Boolean],
    default: false
  }
})

const emits = defineEmits(['update:modelValue'])

const name = toRef(props, 'name')

const { value, errorMessage } = useField(() => name.value, props.rules, {
  syncVModel: true
})

const getButtonClass = computed(() => {
  return twMerge(
    'block relative rounded-md bg-white py-1.5 pl-3 pr-8 text-left ring-0 ring-inset ring-gray-300 focus:outline-none font-bold sm:text-sm sm:leading-6',
    props.buttonClass
  )
})

const getButtonLabelClass = computed(() => {
  return twMerge('block truncate', props.buttonLabelClass)
})

const getListboxOptionsClass = computed(() => {
  return twMerge(
    'absolute z-10 mt-1 max-h-65  w-auto overflow-auto rounded-md bg-white text-base shadow-lg  focus:outline-none sm:text-sm', //  py-1 min-w-full ring-1 ring-black ring-opacity-5
    props.listboxOptionsClass
  )
})

const getOptionClass = active => {
  return twMerge(
    'relative cursor-pointer select-none py-2 pl-3 pr-3',
    props.optionClass,
    active ? 'bg-primary text-white ' + props.optionActiveClass : ''
  )
}

const getOptionLabelClass = selected => {
  return twMerge(selected ? 'font-semibold' : 'font-normal', 'block truncate')
}

// const selectId = computed(() => uniqueId('select_'));
</script>
